import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWards(ctx, params) {
      return useJwt.getWards(params).then(response => response);
    },
    createWard(ctx, data) {
      return useJwt.createFloor(data).then(response => response);
    },
    updateWard(ctx, data) {
      return useJwt.updateFloor(data).then(response => response);
    },
    deleteWards(ctx, data) {
      return useJwt.deleteFloors(data).then(response => response);
    },
  },
};
