import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import wardStoreModule from '../wardStoreModule';

export default function useWardModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'ward';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, wardStoreModule);
  }

  const toastification = toast();

  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    if (itemLocal.value.id && itemLocal.value.id.length > 0) {
      store
        .dispatch('ward/updateWard', itemLocal.value)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('ward/createWard', itemLocal.value)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
